import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  App,
  Button,
  Cascader,
  Col,
  Collapse,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import LocaleContext from 'components/locale/LocaleContext';
import dayjs from 'dayjs';
import {
  useGetLocationQuery,
  useGetStopsSuggestedMutation,
} from 'features/locations/locationsApiSlice';
import { useRecalcutateTimesMutation } from 'features/routes/routesApiSlice';
import { filter, find } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  cloneRouteObject,
  getOnlyAvailableRoutes,
  insertNewItem,
  nameGroupByRoutes,
  filterLoads,
} from 'services/repeated-functions';
import { RoutePropTypes } from 'types';
import {
  ItemSelectedPropTypes,
  OrganizationPropTypes,
  SettingsPropTypes,
} from '../../routesDemand.propTypes';

const { Option } = Select;
const { Text } = Typography;

function RouteEditorDrawer(props) {
  const {
    setDrawerOpen,
    drawerOpen,
    setRoutes,
    setAllRoutes,
    allRoutes,
    setIsRoutingSetUnchanged,
    organization,
    settingsFields,
    itemSelected,
    setItemSelected,
    setSelectedRoute,
  } = props;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [clientId, setClientId] = useState();
  const [client, setClient] = useState();
  const [itemLoadsDisabled, setItemLoadsDisabled] = useState(false);
  const [clientNotFound, setClientNotFound] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fieldsWithError, setFieldsWithError] = useState([]);
  const [activePanelKey, setActivePanelKey] = useState();
  const [fieldToSearch, setFieldToSearch] = useState('nid');
  const [touchedRoutes, setTouchedRoutes] = useState([]);
  const [deletedRoutesIds, setDeletedRoutesIds] = useState(undefined);
  const [form] = Form.useForm();
  const loadsFromOrg = organization.loadsUnit;
  const [getStopsSuggestedMutation] = useGetStopsSuggestedMutation();
  const [recalcutateTimes] = useRecalcutateTimesMutation();
  const { data: locationData } = useGetLocationQuery(clientId, {
    skip: clientId === undefined,
  });
  const { message } = App.useApp();
  const { getI18n } = useContext(LocaleContext);
  const i18n = getI18n();
  const scopeI18n = { scope: 'routes.editor.drawer' };

  const generalSettings = settingsFields
    .find(({ type }) => type === 'GENERALROUTING')
    ?.keyValue?.reduce((summ, setting) => {
      const [name, value] = Object.entries(setting)[0];
      return {
        ...summ,
        [name]: value,
      };
    }, {});

  const defaultFixedDeliveryStopCost = generalSettings.enableFixedDeliveryStopCost
    ? generalSettings.fixedDeliveryStopCost
    : 0;

  let timeout;
  let currentValue;
  const fetch = (valueToSearch, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = valueToSearch;
    const callSuggestedStops = async () => {
      const result = await getStopsSuggestedMutation(`?${fieldToSearch}=${valueToSearch}`).unwrap();
      // show message if not match
      setClientNotFound(result.length === 0);

      if (currentValue === valueToSearch) {
        // show field to search as label in select
        const dataFounded = result.map(({ id: valueResult, [fieldToSearch]: text }) => ({
          value: valueResult,
          text,
        }));
        callback(dataFounded);
      }
    };
    if (valueToSearch) {
      timeout = setTimeout(callSuggestedStops, 500);
    } else {
      callback([]);
    }
  };

  const handleSearch = (newValue) => {
    if (newValue.length >= 3) {
      fetch(newValue, setData);
    }
  };

  useEffect(() => {
    const clientToUse = clientId ? locationData : undefined;
    setClient(clientToUse || itemSelected);
  }, [itemSelected, locationData, clientId]);

  useEffect(() => {
    let formValuesFromItem = {};
    if (itemSelected) {
      setItemLoadsDisabled(itemSelected?.itemDetails?.length > 0);
      formValuesFromItem = {
        id: itemSelected.id,
        restrictions: itemSelected.restrictions,
        trackingId: itemSelected.trackingId,
        vip: itemSelected.vip,
        clientId: itemSelected.id,
        routeNumber: itemSelected.routeNumber,
        itemOrder: itemSelected.itemOrder,
        action: itemSelected.action,
        fixedDeliveryStopCost: dayjs(itemSelected.departsAt).diff(
          dayjs(itemSelected.arrivesAt),
          'minutes'
        ),
        loads: itemSelected.loads,
        itemDetails: itemSelected.itemDetails,
        addressForm: [
          // for item object is called addr
          itemSelected?.addr?.country,
          itemSelected?.addr?.province,
          itemSelected?.addr?.city,
        ],
        street: itemSelected?.addr?.street,
        number: itemSelected?.addr?.number,
        line2: itemSelected?.addr?.line2,
      };
    }
    form.setFieldsValue({
      nid: client?.nid,
      name: client?.name,
      contactId: client?.contactId,
      contactName: client?.contactName,
      contactPhones: client?.contactPhones && client?.contactPhones[0]?.phone,
      contactEmails: client?.contactEmails && client?.contactEmails[0]?.email,
      addressForm: [
        client?.addresses?.country,
        client?.addresses?.province,
        client?.addresses?.city,
      ],
      street: client?.addresses?.street,
      number: client?.addresses?.number,
      line2: client?.addresses?.line2,
      latitude: client?.latitude,
      longitude: client?.longitude,
      ...formValuesFromItem,
    });
  }, [client, form, itemSelected]);

  const initialValues = {
    searchVisit: 'nid',
    // action is first letter upcase
    action: organization.defaultAction[0].toUpperCase(),
    fixedDeliveryStopCost: defaultFixedDeliveryStopCost,
    loads: loadsFromOrg.map(() => ({ load: null })),
  };

  const onChangeForm = (changed, values) => {
    const { clientId: selectedClientId, searchVisit } = changed;
    const { clientId: hasClient } = values;
    const restartForm = () => {
      setData([]);
      setClientId();
      setClient();
      setClientNotFound(false);
      // remove error alert, reset form and disable active panel
      setFieldsWithError(false);
      form.resetFields();
      form.setFieldValue('searchVisit', searchVisit || fieldToSearch);
      setActivePanelKey();
      setItemLoadsDisabled(false);
    };
    // if change search visit assign new value, to change message and filter to endpoint
    if (searchVisit) {
      setFieldToSearch(searchVisit);
      restartForm();
    }
    // If I have the client ID, we will search for the other data
    if (Object.keys(changed).includes('clientId')) {
      setClientId(selectedClientId);
    }
    if (!hasClient) {
      restartForm();
    }

    if ('itemDetails' in changed) {
      const defaultProductLoads = loadsFromOrg.map(() => {
        return { load: 0 };
      });
      const { itemDetails } = form.getFieldsValue();
      itemDetails?.forEach((details) => {
        details?.loads?.forEach((detail, index) => {
          if (defaultProductLoads[index]) {
            const itemValue = !detail?.load ? 0 : detail?.load;
            defaultProductLoads[index].load += itemValue;
          }
        });
      });
      form.setFieldsValue({ loads: defaultProductLoads });
      // enable demand load fields
      setItemLoadsDisabled(changed.itemDetails.length > 0);
    }
  };

  const onFinishFailed = ({ errorFields }) => {
    const fieldsWithErrorInForm = errorFields.map(({ name: nameField }) => nameField[0]);
    setFieldsWithError(fieldsWithErrorInForm);
    // change active panel
    if (
      ['routeNumber', 'itemOrder', 'action', 'fixedDeliveryStopCost'].some(
        (r) => fieldsWithErrorInForm.indexOf(r) >= 0
      )
    ) {
      setActivePanelKey('routeDetail');
    } else if (fieldsWithErrorInForm.includes('loads')) {
      setActivePanelKey('loads');
    } else if (fieldsWithErrorInForm.includes('itemDetails')) {
      setActivePanelKey('itemDetail');
    }
    setButtonLoading(false);
  };
  const onClose = (event) => {
    event?.stopPropagation();
    form.resetFields();
    setItemSelected();
    setClient();
    setData([]);
    setFieldsWithError(false);
    setClientId();
    setActivePanelKey();
    setItemLoadsDisabled(false);
    setDrawerOpen(false);
    setDeletedRoutesIds();
    setClientNotFound(false);
  };

  const searchItemInRoutes = (itemToSearch) => {
    const routesToUse = allRoutes.map((route) => {
      const { items } = route;
      const itemsRest = items.filter((item) => item.id !== itemToSearch.id);
      return { ...route, items: itemsRest };
    });
    return routesToUse;
  };

  const buildRoutes = (newItem) => {
    const { routeNumber, itemOrder } = newItem;
    const routeOrders = allRoutes.map(({ routeOrder }) => routeOrder);

    const isEdit = itemSelected;
    // search for which route the item was on and remove the item from the route, to follow the normal flow
    const routesForUse = isEdit ? searchItemInRoutes(newItem) : allRoutes;

    let destinationRouteObject;
    if (routeOrders.includes(routeNumber)) {
      destinationRouteObject = find(routesForUse, ['routeOrder', parseInt(routeNumber, 10)]);
    } else {
      // clone a route
      let routeOrderId = routeNumber;
      if (routeNumber > routesForUse.length) {
        routeOrderId = routesForUse.length + 1;
      }
      destinationRouteObject = cloneRouteObject(routesForUse, routeOrderId);
    }
    const destinationRouteItems = destinationRouteObject?.items;
    let lastReportedItemOrder;
    if (destinationRouteObject.status === 'STARTED') {
      const lastReportedItem = destinationRouteItems.findLast(
        (arrItem) => arrItem.editable === false
      );
      lastReportedItemOrder = lastReportedItem?.itemOrder;
    }

    // obtain the RTD route item
    const { itemOrder: rtdItemOrder } = destinationRouteItems.find(
      (arrItems) => arrItems.type === 'RETURN_TO_DEPOT'
    );
    let insertItemIndex = itemOrder;
    if (insertItemIndex >= rtdItemOrder) {
      // If it is an empty route I add it as the first item
      if (rtdItemOrder === 1) {
        insertItemIndex = 1;
      } else {
        // obtain the last route item
        const { itemOrder: lastItemOrder } = destinationRouteItems.findLast(
          (arrItems) => arrItems.type === 'STOP'
        );
        // add it to the end
        insertItemIndex = lastItemOrder + 1;
      }
    }
    if (insertItemIndex <= lastReportedItemOrder) {
      insertItemIndex = lastReportedItemOrder + 1;
    }
    // or move or replace
    const newDestinationItems = insertNewItem(
      destinationRouteItems,
      insertItemIndex,
      {
        ...newItem,
        routeId: destinationRouteItems.id,
      },
      routesForUse
    );
    // ensure that RTD item is always in the last position
    const rtdItem = newDestinationItems.find(
      (newDestinationItem) => newDestinationItem.type === 'RETURN_TO_DEPOT'
    );
    const reorderedItems = newDestinationItems
      .filter(({ type }) => type !== 'RETURN_TO_DEPOT')
      .concat(rtdItem)
      .map((itemObj, idx) => {
        return { ...itemObj, itemOrder: idx };
      });

    const otherRoutesEdited = [];
    // When editing you can move an item to another route, the original route must be returned without the item that was moved.
    if (isEdit) {
      // check if the item's route is different from the destination's route
      if (itemSelected.routeNumber !== newItem.routeNumber) {
        const originRoute = routesForUse.find(
          (route) => route.routeOrder === itemSelected.routeNumber
        );
        const reorderedOriginalItems = originRoute.items.map((itemObj, idx) => {
          return { ...itemObj, itemOrder: idx };
        });
        const routeChanged = {
          ...originRoute,
          items: reorderedOriginalItems,
        };
        const totalOriginalStops = filter(reorderedOriginalItems, ['type', 'STOP']).length;
        const originalRouteHasToRemoved = totalOriginalStops === 0;
        // The original route may be left without items, so it should be discarded.
        if (originalRouteHasToRemoved) {
          setDeletedRoutesIds(itemSelected.routeNumber);
          // If the route has no elements left, it should not be sent.
        } else {
          // send the route if it still has items
          otherRoutesEdited.push(routeChanged);
        }
      }
    }

    // ToDo: revisar lo de item edit para dejar una logica similar
    // always once route each time
    const allRoutesDrawer = [
      {
        ...destinationRouteObject,
        items: reorderedItems,
      },
      ...otherRoutesEdited,
    ];
    setTouchedRoutes(allRoutesDrawer);
  };

  useEffect(() => {
    const getTimes = async () => {
      // get group name for every route to send
      const demandGroupRoutes = nameGroupByRoutes(touchedRoutes);
      const newRoutesRecalc1 = await Promise.all(
        touchedRoutes?.map(async (tRoute) => {
          const tRouteFill = { ...tRoute, demandGroupRoutes };
          const { data: dataResponse } = await recalcutateTimes(tRouteFill);
          return {
            ...tRoute,
            ...dataResponse,
          };
        })
      );
      if (touchedRoutes.length > 0) {
        setRoutes(newRoutesRecalc1);
        // modify all universe of routes to apply filters
        const onlyAvailableRoutes = getOnlyAvailableRoutes(
          allRoutes,
          newRoutesRecalc1,
          deletedRoutesIds
        );
        setAllRoutes(onlyAvailableRoutes);
        // reset all selected routes
        if (deletedRoutesIds) {
          const SELECT_ALL = -1;
          setSelectedRoute(SELECT_ALL);
        }
        setIsRoutingSetUnchanged(false);
      }
    };
    getTimes();
    //! skip pass allRoutes to skip recall
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalcutateTimes, setRoutes, setAllRoutes, touchedRoutes, setIsRoutingSetUnchanged]);

  const onFinish = async (values) => {
    setButtonLoading(true);
    form.validateFields();
    const {
      contactPhones,
      contactEmails,
      addressForm = [],
      street,
      number,
      line2,
      fixedDeliveryStopCost,
      loads,
      routeNumber,
    } = values;
    // date sended (now) is changed in backend for day in route
    const now = dayjs();
    const newItem = {
      ...values,
      // add restrictions and events to avoid error in backend
      restrictions: values?.restrictions ?? [],
      events: [], // in theory, items should not be sent with events (was reported), anyway you can do the same as above.
      type: 'STOP',
      contactPhones: [{ phone: contactPhones }],
      contactEmails: [{ email: contactEmails }],
      // check all loads, if has null as value replace with 0
      loads: loads.map(({ load }) => {
        return { load: load || 0 };
      }),
      addr: {
        country: addressForm[0],
        province: addressForm[1],
        city: addressForm[2],
        street,
        number,
        line2,
      },
      arrivesAt: now,
      serviceStartsAt: now,
      // ToDo: add anothers time cost
      departsAt: now.clone().add(fixedDeliveryStopCost, 'minutes'),
    };

    setFieldsWithError(false);
    buildRoutes(newItem);
    setTimeout(() => {
      setButtonLoading(false);
      setClient();
      setItemSelected();
      onClose();
      message.success(
        i18n.t(`alerts.${itemSelected !== undefined ? 'itemUpdated' : 'itemCreated'}`, {
          ...scopeI18n,
          routeNumber,
        })
      );
    }, 1000);
  };

  const handleOnChangePanel = (active) => {
    setActivePanelKey(active);
  };

  const footer = (
    <div className="footer-drawer-buttons">
      <Button type="default" onClick={onClose}>
        {i18n.t('cancel', { scope: 'buttons' })}
      </Button>
      <Button
        type="primary"
        style={{ marginLeft: '1rem' }}
        onClick={() => form.submit()}
        disabled={!client}
        title={!client ? i18n.t('mustSelectClient', scopeI18n) : ''}
        loading={buttonLoading}
      >
        {i18n.t('save', { scope: 'buttons' })}
      </Button>
    </div>
  );

  const searchByLabel = i18n.t(fieldToSearch === 'nid' ? 'nid' : 'name', scopeI18n);
  const itemsCollapseItemDetail = (fields, remove) => {
    return fields.map((field, idx) => {
      const name = i18n.t('itemDetailNumber', { ...scopeI18n, number: idx + 1 });
      return {
        label: name,
        key: field.key,
        forceRender: true,
        children: (
          <>
            <Row justify="end">
              <Button
                type="primary"
                danger
                onClick={() => remove(field.name)}
                className="trash-btn"
                title={i18n.t('commons.archive')}
              >
                <FontAwesomeIcon icon={['fas', 'trash']} color="#FFFFFF" />
              </Button>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={i18n.t('form.code', scopeI18n)}
                  name={[field.name, 'code']}
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder={i18n.t('form.helps.code', scopeI18n)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={i18n.t('form.description', scopeI18n)}
                  name={[field.name, 'description']}
                  required
                  rules={[{ required: true }]}
                >
                  <Input placeholder={i18n.t('form.helps.description', scopeI18n)} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={i18n.t('form.quantity', scopeI18n)}
                  name={[field.name, 'quantity']}
                  required
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    addonAfter={
                      <Select
                        defaultValue={i18n.t('form.unit', scopeI18n)}
                        className="unit-options"
                      >
                        <Option value={i18n.t('form.unit', scopeI18n)}>
                          {i18n.t('form.unit', scopeI18n)}
                        </Option>
                      </Select>
                    }
                    placeholder={i18n.t('form.quantity', scopeI18n)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0" style={{ margin: 0 }}>
              <Text strong>{i18n.t('form.loads', scopeI18n)}</Text>
            </Divider>
            <Form.List
              name={[field.name, 'loads']}
              rules={[
                () => ({
                  validator(_, loads) {
                    const validLoads = filterLoads(loads, loadsFromOrg);
                    if (validLoads && validLoads.length > loadsFromOrg.length) {
                      return Promise.reject(new Error(i18n.t('errors.maxLoads', scopeI18n)));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {(loads, { errors }) => (
                <>
                  <Row justify="end">
                    <Col span={8}>
                      <Form.ErrorList errors={errors} />
                    </Col>
                  </Row>
                  <Row justify="space-evenly" style={{ marginTop: 7 }}>
                    {loads.map(({ key, name: loadName, ...restField }, loadIndex) => {
                      const { isListField } = restField;
                      const loadsFromOrgData = loadsFromOrg.find(
                        (load) => load.key === loadIndex + 1
                      );
                      return (
                        loadsFromOrgData && (
                          <Col span={7} key={key}>
                            <Space
                              key={key}
                              style={{
                                display: 'flex',
                              }}
                            >
                              <Form.Item
                                isListField={isListField}
                                label={loadsFromOrgData?.name}
                                name={[loadName, 'load']}
                                required={key === 0}
                                rules={[{ required: key === 0 }]}
                              >
                                <InputNumber
                                  placeholder={loadsFromOrgData?.name}
                                  addonAfter={loadsFromOrgData?.unit}
                                />
                              </Form.Item>
                            </Space>
                          </Col>
                        )
                      );
                    })}
                  </Row>
                </>
              )}
            </Form.List>
          </>
        ),
      };
    });
  };
  const itemsCollapse = [
    {
      label: i18n.t('form.routeDetail', scopeI18n),
      key: 'routeDetail',
      forceRender: true,
      children: (
        <Row>
          <Col span={11} offset={1}>
            <Form.Item
              name="routeNumber"
              label={i18n.t('form.routeNumber', scopeI18n)}
              required
              rules={[
                { required: true, message: i18n.t('form.helps.mustEnterAValue', scopeI18n) },
                () => ({
                  validator(_, routeNumber) {
                    const routeMatch = allRoutes.find((route) => route.routeOrder === routeNumber);
                    if (routeMatch && !routeMatch.editable) {
                      return Promise.reject(new Error(i18n.t('errors.notEditable', scopeI18n)));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                placeholder={i18n.t('form.helps.routeNumber', scopeI18n)}
                style={{ width: '100%' }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="itemOrder"
              label={i18n.t('form.itemOrder', scopeI18n)}
              required
              rules={[{ required: true, message: i18n.t('form.helps.mustEnterAValue', scopeI18n) }]}
            >
              <InputNumber
                placeholder={i18n.t('form.helps.itemOrder', scopeI18n)}
                style={{ width: '100%' }}
                min={1}
              />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="action"
              label={i18n.t('form.action', scopeI18n)}
              required
              rules={[{ required: true, message: i18n.t('form.helps.mustEnterAValue', scopeI18n) }]}
            >
              <Select
                placeholder={i18n.t('commons.select')}
                options={[
                  { value: 'D', label: i18n.t('commons.delivery') },
                  { value: 'P', label: i18n.t('commons.pickup') },
                ]}
                popupClassName="select-drawer"
              />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              name="fixedDeliveryStopCost"
              label={i18n.t('form.fixedDeliveryStopCost', scopeI18n)}
              required
              rules={[{ required: true, message: i18n.t('form.helps.mustEnterAValue', scopeI18n) }]}
            >
              <InputNumber
                placeholder={i18n.t('form.helps.fixedDeliveryStopCost', scopeI18n)}
                style={{ width: '100%' }}
                min={0}
                addonAfter="Min"
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      label: i18n.t('form.identification', scopeI18n),
      key: 'clientInfo',
      forceRender: true,
      children: (
        <Row>
          <Col span={22} offset={1} style={{ marginBottom: '1rem' }}>
            <Alert
              message={
                <Text>
                  {i18n.t('alerts.rememberChangeInLocation', scopeI18n)}
                  <Button
                    type="link"
                    size="small"
                    className="btn-no-shadow"
                    title={i18n.t('goToVisit', scopeI18n)}
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate(`/locations`);
                    }}
                  >
                    {i18n.t('visits', scopeI18n)}
                  </Button>
                </Text>
              }
              type="warning"
              showIcon
            />
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="nid" label={i18n.t('form.nid', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="name" label={i18n.t('form.name', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="document" label={i18n.t('form.document', scopeI18n)}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={22} offset={1} style={{ marginBottom: '1rem' }}>
            <Divider orientation="left" orientationMargin="0" style={{ margin: 0 }}>
              <Text strong>{i18n.t('form.contactInformation', scopeI18n)}</Text>
            </Divider>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="contactId" label={i18n.t('form.contactId', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="contactName" label={i18n.t('form.contactName', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="contactPhones" label={i18n.t('form.contactPhones', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="contactEmails" label={i18n.t('form.contactEmails', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      label: i18n.t('form.loads', scopeI18n),
      key: 'loads',
      forceRender: true,
      children: (
        <Form.List name="loads">
          {(fields, { errors }) => (
            <>
              <Row justify="end">
                <Col span={8}>
                  <Form.ErrorList errors={errors} />
                </Col>
              </Row>
              <Row justify="space-evenly">
                {fields.map(({ key, name, ...restField }, loadIndex) => {
                  const { isListField } = restField;
                  const loadsFromOrgData = loadsFromOrg.find((load) => load.key === loadIndex + 1);
                  return (
                    loadsFromOrgData && (
                      <Col span={7} key={key} offset={0}>
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                          }}
                        >
                          <Form.Item
                            isListField={isListField}
                            label={loadsFromOrgData?.name}
                            name={[name, 'load']}
                            required={key === 0}
                            rules={[{ required: key === 0 }]}
                          >
                            <InputNumber
                              placeholder={loadsFromOrgData?.name}
                              addonAfter={loadsFromOrgData?.unit}
                              disabled={itemLoadsDisabled}
                              min={0}
                            />
                          </Form.Item>
                        </Space>
                      </Col>
                    )
                  );
                })}
              </Row>
            </>
          )}
        </Form.List>
      ),
    },
    {
      label: i18n.t('form.itemDetails', scopeI18n),
      key: 'itemDetail',
      forceRender: true,
      children: (
        <Form.List name="itemDetails">
          {(fields, { add, remove }) => {
            return (
              <>
                <Row justify="end">
                  <Col style={{ justifyContent: 'flex-end' }}>
                    <Button
                      type="primary"
                      ghost
                      onClick={() =>
                        add({
                          loads: loadsFromOrg.map(() => {
                            return { load: null };
                          }),
                        })
                      }
                    >
                      {i18n.t('form.addItemDetail', scopeI18n)}
                    </Button>
                  </Col>
                </Row>
                <br />
                <Collapse accordion items={itemsCollapseItemDetail(fields, remove)} />
              </>
            );
          }}
        </Form.List>
      ),
    },
    {
      label: i18n.t('form.location', scopeI18n),
      key: 'location',
      forceRender: true,
      children: (
        <Row>
          <Col span={11} offset={1}>
            <Form.Item name="addressForm" label={i18n.t('form.addressForm', scopeI18n)}>
              <Cascader disabled />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="street" label={i18n.t('form.street', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="number" label={i18n.t('form.number', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="line2" label={i18n.t('form.line2', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={22} offset={1} style={{ marginBottom: '1rem' }}>
            <Divider orientation="left" orientationMargin="0" style={{ margin: 0 }}>
              <Text strong>{i18n.t('form.geolocation', scopeI18n)}</Text>
            </Divider>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="latitude" label={i18n.t('form.latitude', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name="longitude" label={i18n.t('form.longitude', scopeI18n)}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  const title = i18n.t(itemSelected ? 'titleEdit' : 'titleCreate', scopeI18n);

  return (
    <Drawer
      title={title}
      footer={footer}
      onClose={(event) => onClose(event)}
      width="680px"
      open={drawerOpen}
      destroyOnClose
      maskClosable={false}
      closable={false}
      forceRender
    >
      <Form
        form={form}
        validateMessages={{ required: i18n.t('errors.fieldRequired', scopeI18n) }}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
        onValuesChange={onChangeForm}
      >
        <Row>
          <Col span={18} offset={6}>
            <Form.Item name="searchVisit" style={{ marginBottom: '1rem' }} hidden={itemSelected}>
              <Radio.Group
                options={[
                  { label: i18n.t('form.nid', scopeI18n), value: 'nid' },
                  { label: i18n.t('form.name', scopeI18n), value: 'name' },
                ]}
                optionType="button"
              />
            </Form.Item>
          </Col>
          <Col span={12} offset={6}>
            <Form.Item
              name="clientId"
              help={i18n.t('searchByType', { ...scopeI18n, type: searchByLabel })}
              hidden={itemSelected}
            >
              <Select
                showSearch
                placeholder={i18n.t('searchClient', scopeI18n)}
                allowClear
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={handleSearch}
                notFoundContent={null}
                options={(data || []).map((d) => ({
                  value: d.value,
                  label: d.text,
                }))}
                popupClassName="select-drawer"
              />
            </Form.Item>
          </Col>
          {fieldsWithError.length > 0 && (
            <Col span={24}>
              <Alert message={i18n.t('errors.fillAllFields', scopeI18n)} type="error" showIcon />
            </Col>
          )}
          {itemSelected && (
            <>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="trackingId" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="restrictions" hidden>
                <Input />
              </Form.Item>
              <Form.Item name="vip" hidden>
                <Input />
              </Form.Item>
            </>
          )}
          {client && (
            <Col span={24} style={{ marginTop: '1rem' }}>
              <Collapse
                accordion
                size="small"
                defaultActiveKey="routeDetail"
                onChange={handleOnChangePanel}
                activeKey={activePanelKey}
                items={itemsCollapse}
              />
            </Col>
          )}
          {clientNotFound && (
            <Col span={12} offset={6} style={{ textAlign: 'center' }}>
              <Text type="secondary">
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                <br />
                {i18n.t('errors.codeNotFound', scopeI18n)}
              </Text>
            </Col>
          )}
        </Row>
      </Form>
    </Drawer>
  );
}

RouteEditorDrawer.defaultProps = {
  setDrawerOpen: () => {},
  drawerOpen: false,
  setRoutes: () => {},
  setAllRoutes: () => {},
  allRoutes: [],
  setIsRoutingSetUnchanged: () => {},
  organization: {},
  settingsFields: [],
  itemSelected: undefined,
  setItemSelected: () => {},
  setSelectedRoute: () => {},
};

RouteEditorDrawer.propTypes = {
  setDrawerOpen: PropTypes.func,
  drawerOpen: PropTypes.bool,
  setRoutes: PropTypes.func,
  setAllRoutes: PropTypes.func,
  allRoutes: PropTypes.arrayOf(RoutePropTypes),
  setIsRoutingSetUnchanged: PropTypes.func,
  organization: OrganizationPropTypes,
  settingsFields: SettingsPropTypes,
  itemSelected: ItemSelectedPropTypes,
  setItemSelected: PropTypes.func,
  setSelectedRoute: PropTypes.func,
};

export default RouteEditorDrawer;
