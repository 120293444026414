import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Checkbox, Col, Row } from 'antd';
import RouteResumeCard from 'components/RoutesDemand/RouteResumeCard';
import { hideMapNote, selectIsMapNoteHidden } from 'features/app/appSlice';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import Control from 'react-leaflet-custom-control';
import { useDispatch, useSelector } from 'react-redux';
import FullscreenControl from './FullscreenControl';
import { pointShapePropTypes, resumeCardContentPropTypes } from './LeafletMap.propType';
import LeafletMarkers from './LeafletMarkers';

import 'leaflet/dist/leaflet.css';
import './style.css';

function LeafletMap(props) {
  const {
    dataSource,
    showRoutes,
    onFinishLoad,
    resumeCardContent,
    baseSpanCardContent,
    useContainerSize,
    setMapFullSize,
    mapFullSize,
    useCustomFullscreen, // this button expand container, used with setMapFullsize
    typeMarkers,
    showAlertRoutes,
    moveToPosition,
    markerTriggerCallback,
    enableScroll,
    refResumeCard,
    defaultCoords,
    markerType,
    zoom,
    setSelectedPoint,
    enableMoveToPosition,
    useCluster,
  } = props;
  const dispatch = useDispatch();
  const isMapNoteHidden = useSelector(selectIsMapNoteHidden);
  const [showCard, setShowCard] = useState(!isMapNoteHidden);
  const [hideNote, setHideNote] = useState(false);

  useEffect(() => {
    onFinishLoad();
  }, [onFinishLoad]);

  const resizeMap = (mapRef) => {
    const resizeObserver = new ResizeObserver(() => mapRef.current?.invalidateSize());
    const container = document.getElementById('map-container');
    if (container) {
      resizeObserver.observe(container);
    }
  };

  // map
  const here = {
    apiKey: process.env.REACT_APP_HERE_API_KEY,
  };
  const style = 'explore.day';
  const hereTileUrl = `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?apiKey=${here.apiKey}&style=${style}&size=512`;
  const center = defaultCoords || [-33.437060687304395, -70.65057630171597];
  // use false to skip warning when hide map
  const zoomAnimation = false;
  const mapRef = useRef(null);
  const styleMapContainer = useContainerSize ? { height: '95%', minHeight: '95%' } : {};

  const closeNote = (e) => {
    e.stopPropagation();
    setShowCard(!showCard);
    if (hideNote) {
      dispatch(hideMapNote());
    }
  };

  const onclickFullSize = () => {
    setTimeout(() => {
      setMapFullSize(!mapFullSize);
      resizeMap(mapRef);
    }, 100);
  };

  if (moveToPosition && enableMoveToPosition) {
    setTimeout(() => {
      mapRef.current?.flyTo(moveToPosition, 15, { duration: 1 });
    }, 1000);
  }

  return (
    <MapContainer
      ref={mapRef}
      id="map-container"
      whenReady={() => resizeMap(mapRef)}
      center={center}
      zoom={zoom}
      scrollWheelZoom={enableScroll}
      zoomControl={false}
      zoomAnimation={zoomAnimation}
      style={styleMapContainer}
      preferCanvas
    >
      {showAlertRoutes && showCard && !showRoutes && (
        <div className="map-card">
          <Row>
            <Col span={12} offset={6}>
              <Card title="Nota" size="small">
                <p>
                  El número dentro de cada pin en el mapa es a modo informativo, no implica que este
                  sea el orden de la ruta.
                </p>
                <p>
                  <Checkbox onClick={() => setHideNote(true)}>
                    No volver a mostrar este mensaje
                  </Checkbox>
                </p>
                <p>
                  <Button style={{ float: 'right' }} type="link" onClick={closeNote}>
                    Entendido
                  </Button>
                </p>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {resumeCardContent && (
        <div className="map-card" ref={refResumeCard}>
          <RouteResumeCard cardGrid={resumeCardContent} baseSpanCardContent={baseSpanCardContent} />
        </div>
      )}
      <TileLayer attribution="&copy; HERE" url={hereTileUrl} preferCanvas />
      <ZoomControl position="bottomright" />
      {!useCustomFullscreen && <FullscreenControl position="bottomright" />}
      {useCustomFullscreen && (
        <Control prepend position="bottomright">
          <Button color="inherit" className="custom-button-fullscreen" onClick={onclickFullSize}>
            <FontAwesomeIcon
              icon={mapFullSize ? 'minimize' : 'maximize'}
              title={mapFullSize ? 'Contraer' : 'Expandir'}
            />
          </Button>
        </Control>
      )}
      {useCluster && (
        <MarkerClusterGroup chunkedLoading>
          <LeafletMarkers
            points={dataSource}
            showRoutes={showRoutes}
            typeMarkers={typeMarkers}
            markerTriggerCallback={markerTriggerCallback}
            defaultCoords={center}
            markerType={markerType}
            setSelectedPoint={setSelectedPoint}
          />
        </MarkerClusterGroup>
      )}
      {!useCluster && (
        <LeafletMarkers
          points={dataSource}
          showRoutes={showRoutes}
          typeMarkers={typeMarkers}
          markerTriggerCallback={markerTriggerCallback}
          defaultCoords={center}
          markerType={markerType}
          setSelectedPoint={setSelectedPoint}
        />
      )}
    </MapContainer>
  );
}

LeafletMap.defaultProps = {
  dataSource: [],
  showRoutes: false,
  resumeCardContent: [],
  onFinishLoad: () => {},
  useContainerSize: true,
  setMapFullSize: undefined,
  mapFullSize: false,
  useCustomFullscreen: false,
  typeMarkers: 'routes',
  showAlertRoutes: false,
  baseSpanCardContent: { span: 20, offset: 2 },
  moveToPosition: undefined,
  markerTriggerCallback: undefined,
  enableScroll: true,
  refResumeCard: undefined,
  defaultCoords: undefined,
  markerType: 'normal',
  zoom: 13,
  setSelectedPoint: () => {},
  enableMoveToPosition: true,
  useCluster: false,
};

LeafletMap.propTypes = {
  dataSource: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(pointShapePropTypes), pointShapePropTypes])
  ),
  showRoutes: PropTypes.bool,
  resumeCardContent: PropTypes.arrayOf(resumeCardContentPropTypes),
  onFinishLoad: PropTypes.func,
  useContainerSize: PropTypes.bool,
  setMapFullSize: PropTypes.func,
  mapFullSize: PropTypes.bool,
  useCustomFullscreen: PropTypes.bool,
  typeMarkers: PropTypes.string,
  showAlertRoutes: PropTypes.bool,
  baseSpanCardContent: PropTypes.shape({
    span: PropTypes.number,
    offset: PropTypes.number,
  }),
  moveToPosition: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  markerTriggerCallback: PropTypes.func,
  enableScroll: PropTypes.bool,
  refResumeCard: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    current: PropTypes.any,
  }),
  defaultCoords: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  markerType: PropTypes.string,
  zoom: PropTypes.number,
  setSelectedPoint: PropTypes.func,
  enableMoveToPosition: PropTypes.bool,
  useCluster: PropTypes.bool,
};

export default LeafletMap;
